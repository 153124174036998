import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { Pie } from 'react-chartjs-2';
import data from '../../data/source_data.json';
import {colorGradient} from '../../../shared/utils';

export default class Source extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeSelection: { value: '30', label: '30 days' },
      timeInterval: '30',
      statusSelection: { value: 'Engage', label: 'Engage' },
      status: 'Engage',
      source: null
    };

    this.mainChartRef = React.createRef();
  }

  getMainData() {
    const status = this.state.status === 'All' ? '' : this.state.status;
    return _.get(data, [status, this.state.timeInterval, 'summary']);
  }

  getBreakdownData() {
    const status = this.state.status === 'All' ? '' : this.state.status;
    return this.state.source
      ? _.get(data, [status, this.state.timeInterval, 'details', this.state.source])
      : null
    ;
  }

  colorPalette(count) {
    return colorGradient(colorGradient.defaultLightest, colorGradient.defaultDarkest, count);
  }

  renderMainChart() {
    const mainData = this.getMainData();
    return (
      <Pie
        ref={this.mainChartRef}
        data={{
          labels: mainData.labels,
          datasets: [{
            label: `Startups Added: ${this.state.status}: ${this.state.timeInterval}`,
            backgroundColor: this.colorPalette(_.compact(mainData.counts).length),
            data: mainData.counts,
            borderWidth: 0,
            datalabels: {
              anchor: 'end'
            }
          }]
        }}
        options={{
          legend: {
            display: false
          },
          onHover: (e, el) => e.target.style.cursor = el.length ? 'pointer' : 'default',
          pieceLabel: {
            render: args => `${args.label} (${args.percentage}%)`,
            arc: true,
            position: 'border',
            fontSize: 16,
            fontStyle: 'bold',
            fontColor: '#000',
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                const total = meta.total;
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = parseFloat((currentValue/total*100).toFixed(1));
                return currentValue + ' (' + percentage + '%)';
              },
              title: function(tooltipItem) {
                return mainData.labels[tooltipItem[0].index];
              }
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          }
        }}
        onElementsClick={targets => {
          if (targets.length === 0) return;
          const target = targets[0];
          if (target) {
            const chartData = target._chart.config.data;
            const idx = target._index;

            const label = chartData.labels[idx];
            this.setState({source: label});
          }
        }}
      />
    );
  }

  renderBreakdownChart() {
    const breakdownData = this.getBreakdownData();
    return breakdownData
      ? (
        <Pie
          data={{
            labels: breakdownData.labels,
            datasets: [{
              label: `Startups Added: ${this.state.status}: ${this.state.timeInterval}: ${this.state.source}`,
              backgroundColor: this.colorPalette(breakdownData.labels.length),
              data: breakdownData.counts,
              borderWidth: 0,
              datalabels: {
                anchor: 'end'
              }
            }]
          }}
          options={{
            pieceLabel: {
              render: function (args) {
                return `${args.label} (${args.percentage}%)`;
              },
              arc: true,
              position: 'border',
              fontSize: 16,
              fontStyle: 'bold',
              fontColor: '#000',
            },
            legend: {
              display: false,
              position: 'bottom',

              labels: {
                fontSize: 10,
                boxWidth: 10
              }
            },
            plugins: {
              datalabels: {
                display: false
              }
            }
          }}
          style={{visibility: this.state.source ? 'visible' : 'hidden'}}
        />
      ) : (
        <h4>{this.props.copy.voyager_sources_prompt}</h4>
      )
    ;
  }

  handleStatusChange(selectedStatus) {
    this.setState({
      statusSelection: selectedStatus,
      status: selectedStatus.value,
      loading: true
    }, this.fetchChartData);
  }

  statusFilter() {
    return (
      <Select
        name="industry-breakdown-status-filter"
        clearable={false}
        value={this.state.statusSelection}
        onChange={selected => this.handleStatusChange(selected)}
        options={[
          { value: '', label: 'All' },
          { value: 'Engage', label: 'Engage' },
          { value: 'Evaluate', label: 'Evaluate' },
          { value: 'Term sheet phase', label: 'Term Sheet' },
          { value: 'Portfolio Company', label: 'Portfolio' },
        ]}
      />
    );
  }

  handleTimeChange(selectedTime) {
    this.setState({
      timeSelection: selectedTime,
      timeInterval: selectedTime.value,
      loading: true
    }, this.fetchChartData);
  }

  timeFilter() {
    return (
      <Select
        name="industry-breakdown-time-filter"
        clearable={false}
        value={this.state.timeSelection}
        onChange={selected => this.handleTimeChange(selected)}
        options={[
          { value: '7', label: '7 days' },
          { value: '30', label: '30 days' },
          { value: '90', label: '90 days' },
        ]}
      />
    );
  }

  render() {
    return(
      <div className='source-breakdown-component'>
        <div className='row' style={{marginBottom: '15px'}}>
          <div className='col-md-3'>
            {this.statusFilter()}
          </div>
          <div className='col-md-3'>
            {this.timeFilter()}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            {this.renderMainChart()}
          </div>
          <div className='col-md-6'>
            {this.renderBreakdownChart()}
          </div>
        </div>
      </div>
    );
  }
}

