import _ from 'lodash';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Downloads extends React.Component {
  constructor(props) {
    super(props);

    this.setDocs(props.documents);

    this.state = {};
  }

  setDocs(documents) {
    this.documents = _.pickBy(documents, (v, k) => k !== 'Quarterly Reporting');
    this.documents['Quarterly Reporting'] = _.groupBy(
      documents['Quarterly Reporting']
      , doc => doc.name.substr(0, 4)
    );
  }

  renderToggle(isOpen, iconType) {
    return isOpen
      ? <i className={`toggle fa fa-${iconType}-down`} />
      : <i className={`toggle fa fa-${iconType}-right`} />
    ;
  }

  docFolder(stateKey, docs) {
    if (!this.state[stateKey]) {
      return <React.Fragment />;
    }

    if (_.isArray(docs)) {
      return (
        <div className='documents'>
          {docs.map(doc => {
            return (
              <a href={doc.file_url} target='_blank' key={doc.file_url}>
                {doc.name} <i className='fa fa-cloud-download' />
              </a>
            );
          })}
        </div>
      );
    }
    return (
      <div className='documents'>
        {_.map(docs, (subdocs, year) => {
          const stateSubKey = `${stateKey}/${year}`
            , isOpen = this.state[stateSubKey]
            , whenToggled = {}
          ;
          whenToggled[stateSubKey] = !this.state[stateSubKey];
          return (
            <div className='subdocuments'>
              <div onClick={() => this.setState(whenToggled)} className='doc-subfolder-title'>
                {this.renderToggle(isOpen, 'caret')}
                <span className='text'>{year}</span>
              </div>
              {this.docFolder(stateSubKey, subdocs)}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <Container className='downloads-container' fluid>
        <Row>
          <Col sm='12'>
            <p className='page-description'>
              Download only the documents you need. Our fund overviews, investment track records, references,
              legal documents, early exits, investment memos, quarterly reports, annual meetings, team bios,
              thought leadership, and more.
            </p>
            {Object.keys(this.props.documents).map((docFolder) => {
              return (
                <div className='overview-data-box' key={docFolder}>
                  <div className='doc-folder-title'
                    onClick={() => this.setState({ [docFolder]: !this.state[docFolder]})}>
                    {this.renderToggle(this.state[docFolder], 'chevron')}
                    <span className='text'>{docFolder}</span>
                  </div>

                  {this.docFolder(docFolder, this.documents[docFolder])}
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  }
}
