import React from 'react';
import { Pie } from 'react-chartjs-2';
import rawData from '../../data/stage_data.json';
import {colorGradient} from '../../../shared/utils';

export default class Stage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  colorPalette(count) {
    return colorGradient(colorGradient.defaultLightest, colorGradient.defaultDarkest, count);
  }

  render() {
    const data = rawData.data;
    return (
      <Pie
        data={{
          labels: Object.keys(data),
          datasets: [{
            label: 'Diverse Team',
            backgroundColor: this.colorPalette(Object.keys(data).length),
            data: Object.values(data),
            borderWidth: 0,
            datalabels: {
              anchor: 'end'
            }
          }]
        }}
        options={{
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontSize: 10,
              boxWidth: 10
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          }
        }}
      />
    );
  }
}
