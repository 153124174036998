import React from 'react';
import { Bar } from 'react-chartjs-2';
import rawData from '../../data/industries_data.json';
import {colorGradient} from '../../../shared/utils';

export default class IndustryBreakdown extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  colorPalette(count) {
    return colorGradient(colorGradient.defaultLightest, colorGradient.defaultDarkest, count);
  }

  render() {
    const data = rawData.data;
    return (
      <Bar
        data={{
          labels: data.labels,
          datasets: [{
            label: 'Startups Added',
            data: data.counts,
            ids: data.ids,
            backgroundColor: this.colorPalette(data.ids.length),
            borderWidth: 0,
            datalabels: {
              anchor: 'end'
            }
          }]
        }}
        options={{
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              ticks: {
                suggestedMax: 10,
                beginAtZero: true
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce((sum, x) => sum + x);
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = parseFloat((currentValue/total*100.0).toFixed(1));
                return currentValue + ' (' + percentage + '%)';
              },
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              }
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          }

        }}
      />
    );
  }
}
