import _ from 'lodash';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import MeetingsPace from './voyager/MeetingsPace';
import Stage from './voyager/Stage';
import IndustryBreakdown from './voyager/IndustryBreakdown';
import Map from './voyager/Map';
import Source from './voyager/Source';

export default class Voyager extends React.Component {
  constructor(props) {
    super(props);
    this.copy = _.reduce(props.copy, (obj, copy) => _.set(obj, copy.slug, copy.copy), {});

    this.state = {};
  }


  render() {
    return (
      <Container fluid>
        <Row className='overview-data-box voyager-chart pace-chart'>
          <Col sm='12'>
            <div className='page-description'>{this.copy.voyager_disclaimer}</div>
            <hr />
            <h3>First Meetings Pace</h3>
            <MeetingsPace />
          </Col>
        </Row>
        <Row className='overview-data-box voyager-chart stage-chart'>
          <Col sm='12'>
            <h3>Stage of Pipeline Deals (Last 90 days)</h3>
            <Stage />
          </Col>
        </Row>
        <Row className='overview-data-box voyager-chart source-chart'>
          <Col sm='12'>
            <h3>Sources Breakdown</h3>
            <Source copy={this.copy} />
          </Col>
        </Row>
        <Row className='overview-data-box voyager-chart industry-chart'>
          <Col sm='12'>
            <h3>Industries Breakdown (Last 90 days)</h3>
            <IndustryBreakdown />
          </Col>
        </Row>
        <Row className='overview-data-box voyager-chart map-chart'>
          <Col sm='12'>
            <h3>Sources Geography (All time)</h3>
            <Map />
          </Col>
        </Row>
      </Container>
    );
  }
}
