import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_usaLow from '@amcharts/amcharts4-geodata/usaLow';
import {colorGradient} from '../../../shared/utils';

import data from '../../data/map_data.json';

export default class IndustryBreakdown extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
    this.mapRef = React.createRef();
  }

  colorFor(value) {
    const colors =  colorGradient(colorGradient.defaultLightest, colorGradient.defaultDarkest, 6);
    if (value < 25) {
      return colors[0];
    } else if (value < 50) {
      return colors[1];
    } else if (value < 100) {
      return colors[2];
    } else if (value < 200) {
      return colors[3];
    } else if (value < 500) {
      return colors[4];
    } else {
      return colors[5];
    }
  }

  componentDidMount() {
    let chart = am4core.create(this.mapRef.current, am4maps.MapChart);
    chart.height = 300;

    chart.projection = new am4maps.projections.Miller();
    chart.geodata = am4geodata_usaLow;

    chart.zoomLevel = 4;

    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ['US-AK', 'US-HI'];
    polygonSeries.data = data.counts.map((entry) => {
      entry.fill = am4core.color(this.colorFor(entry.value));
      return entry;
    });

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;

    polygonTemplate.tooltipText = '{name} ({value})';
    polygonTemplate.propertyFields.fill = 'fill';

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create('hover');
    hs.properties.fill = am4core.color('#FD2A33');
  }

  render() {
    return (
      <div ref={this.mapRef} className='map-container' />
    );
  }

}
