import ReactOnRails from 'react-on-rails';

import DataRoom from '../bundles/DataRoom/components/DataRoom';
import Downloads from '../bundles/DataRoom/components/Downloads';
import Dashboard from '../bundles/Dashboard/components/Dashboard';
import AnnualMeeting from '../bundles/AnnualMeeting/components/AnnualMeeting';
import Voyager from '../bundles/DataRoom/components/Voyager';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  DataRoom,
  Downloads,
  Dashboard,
  AnnualMeeting,
  Voyager
});
