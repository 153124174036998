import React from 'react';
import { Row, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import data from '../../data/meetings_pace_data.json';
import {colorGradient} from '../../../shared/utils';

export default class MeetingsPace extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // Keeping it in the same format as the Voyager component for easier copy/pasting changes.
    const paceData = {
        initialMeetings: data.last_30_days,
        engageMovements: data.engage_movements,
        toEngageConversion: data.targets.to_engage_conversion_rate,
        evaluateMovements: data.evaluate_movements,
        toEvaluateConversion: data.targets.to_evaluate_conversion_rate,
        termSheetMovements: data.term_sheet_movements,
        toTermSheetConversion: data.targets.to_term_sheet_conversion_rate,
        toPortfolioConversion: data.targets.to_portfolio_conversion_rate,
        newMeetingsData: data.new_meetings_chart_data,
        personalData: data.personal_data,
        engageData: data.engage_chart_data,
        evaluateData: data.evaluate_chart_data,
        termSheetData: data.term_sheet_chart_data,
        loading: false
      }
      , colors =  colorGradient(colorGradient.defaultLightest, colorGradient.defaultDarkest, 4)
    ;

    return (
      <Row className='voyager-chart'>
        <Col sm='8'>
          <Bar
            height={250}
            data={{
              labels: Object.keys(paceData.newMeetingsData),
              datasets: [
                {
                  label: 'First Founders Meetings',
                  data: Object.values(paceData.newMeetingsData).map(startups => startups.length),
                  backgroundColor: colors[0],
                  yAxisID: 'B',
                },
                {
                  label: 'Companies moved to Engage',
                  data: Object.values(paceData.engageData).map(movements => movements.length),
                  backgroundColor: colors[1],
                  yAxisID: 'A',
                },
                {
                  label: 'Companies moved to Evaluate',
                  data: Object.values(paceData.evaluateData).map(movements => movements.length),
                  backgroundColor: colors[2],
                  yAxisID: 'A',
                },
                {
                  label: 'Companies moved to Term Sheet',
                  data: Object.values(paceData.termSheetData).map(movements => movements.length),
                  backgroundColor: colors[3],
                  yAxisID: 'A',
                }
              ]
            }}

            options={{
              tooltips: {
                mode: 'label',
                intersect: true
              },
              scales: {
                xAxes: [{
                  gridLines: { display: false },
                  ticks: {
                    fontColor: '#242946',
                    fontSize: 12,
                  }
                }],
                yAxes: [
                  {
                    gridLines: { display: true },
                    id: 'A',
                    ticks: { fontColor: '#242946', beginAtZero: true, max: 20 },
                    position: 'right'
                  },
                  {
                    gridLines: { display: true },
                    id: 'B',
                    ticks: { fontColor: '#242946', beginAtZero: true, max: 100 } 
                  }
                ],
              },
              plugins: {
                datalabels: {
                  display: false
                }
              }
            }}
          />
        </Col>

        <Col className='analytics-col' sm='4'>
          <span className='analytics-label colored'>
            First Calls (Last 30 days / Monthly Target)
          </span>
          <h3 className='analytics-big-number' style={{fontSize: '32px'}}>
            {paceData.initialMeetings} / 64
          </h3>

          {paceData.toEngageConversion}% Conversion (25% target)

          <br /><br />

          <span className='analytics-label colored'>
            Engage Movements
          </span>
          <h3 className='analytics-big-number' style={{fontSize: '32px'}}>
            {paceData.engageMovements} / 16
          </h3>

          {paceData.toEvaluateConversion}% Conversion (25% target)

          <br /><br />

          <span className='analytics-label colored'>
            Evaluate Movements
          </span>
          <h3 className='analytics-big-number' style={{fontSize: '32px'}}>
            {paceData.evaluateMovements} / 4
          </h3>

          {paceData.toTermSheetConversion}% Conversion (50% target)

          <br /><br />

          <span className='analytics-label colored'>
            Term Sheets Issued
          </span>
          <h3 className='analytics-big-number' style={{fontSize: '32px'}}>
            {paceData.termSheetMovements} / 1
          </h3>

          {paceData.toPortfolioConversion}% Conversion (65% target)

          <br /><br />
        </Col>
      </Row>
    );
  }
}
